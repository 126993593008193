import React from "react"
import { Helmet } from "react-helmet-async"

import PuzzleForm from "../../components/puzzles/PuzzleForm"
import RequireAuth from "../../components/auth/RequireAuth"

const PuzzlesAddPage = () => {
  return (
    <>
      <Helmet title="Add Puzzle - JiggSwap" />
      <RequireAuth />

      <div className="site-content">
        <div className="is-size-2">Add Puzzle</div>
        <div className="is-size-4">This puzzle will be added to your collection.</div>
        <hr />

        <PuzzleForm
          puzzle={{
            tags: [],
          }}
        />
      </div>
    </>
  )
}

export default PuzzlesAddPage
